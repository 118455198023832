<template>
  <b-sidebar
    id="true"
    width="55%"
    :visible="sidebar"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="(val) => $emit('toggle_sidebar', val)"
  >
    <template>
      <b-card class="card-transaction" no-body>
        <b-card-header>
          <b-card-title>Search & {{ newlisting ? "Save" : "Update" }}</b-card-title>
          <div>
            <b-button variant="flat-primary" class="float-right" size="sm" @click="() => $emit('toggle_sidebar', false)">
              <feather-icon icon="XIcon" class="mr-50" />
              <span class="align-middle">Close</span>
            </b-button>
          </div>
        </b-card-header>
        <b-card-body v-if="permissions">
          <template v-if="filter_data && Object.keys(filter_data).length">
            <!-- <template>
              <b-alert variant="success" dismissible fade :show="show_save_alert" class="mb-1" @dismissed="show_save_alert = false">
                <div class="alert-body pr-2">
                  <span>Searched properties will automatically be saved for valid addresses & shown in table as search history</span>
                </div>
              </b-alert>
              <b-alert variant="primary" dismissible fade :show="show_comments_alert" class="mb-1" @dismissed="show_comments_alert = false">
                <div class="alert-body pr-2">
                  <span>Additional data & comments can be added to a listing by adding it to favourites in search history table</span>
                </div>
              </b-alert>
            </template> -->
            <!-- Manual Data form Section Start-->
            <b-row v-for="k in Object.keys(manual_data)" :key="'mf' + k">
              <template v-if="is_manual_data_field_allowed(k)">
                <!-- auto-complete/ auto suggest field (basically using for address field) -->
                <template v-if="manual_fields[k].input_type == 'suggest'">
                  <b-col cols="12" class="mb-1 d-flex align-items-baseline justify-content-between">
                    <label class="mr-2">{{ manual_fields[k].label }}</label>
                    <vue-autosuggest
                      v-model="manual_data[k]"
                      :input-props="{
                        id: 'autosuggest_' + k,
                        class: 'form-control width-70',
                        placeholder: 'Type to get suggestions',
                        disabled: manual_fields[k].disabled,
                      }"
                      :suggestions="[{ data: manual_fields[k].fdata }]"
                      @input="getsuggestions(k)"
                      @selected="(s) => set_autosuggest_value(s, k)"
                      :limit="20"
                      component-attr-class-autosuggest-results-container="sidebar-autosuggest"
                      :loading="true"
                    >
                      <template slot-scope="{ suggestion }">
                        <span>{{ suggestion.item.address }}</span>
                      </template>
                    </vue-autosuggest>
                  </b-col>
                </template>

                <!-- presaved values: select fields -->
                <template v-else-if="manual_fields[k].presaved">
                  <b-col cols="12" md="12" class="d-flex align-items-baseline justify-content-between" :key="'ps' + k">
                    <label class="mr-2">{{ manual_fields[k].label }}</label>
                    <v-select
                      v-model="manual_data[k]"
                      :options="filter_data[manual_fields[k].fdata]"
                      :label="manual_fields[k].fdata_field"
                      :reduce="(v) => (manual_fields[k].fdata_value ? v[manual_fields[k].fdata_value] : v[manual_fields[k].fdata_field])"
                      class="width-70 mb-1 capitalize-text"
                      :placeholder="'Select ' + manual_fields[k].label"
                      :multiple="manual_fields[k].multiple"
                    />
                  </b-col>
                </template>

                <!-- Agent fields -->
                <template v-else-if="manual_fields[k].input_type == 'custom_agent'">
                  <b-col cols="12" class="mb-1" style="background-color: #f2f4f5">
                    <div class="d-flex align-items-center justify-content-between">
                      <label>
                        {{ manual_fields[k].label }}
                      </label>
                      <b-button variant="flat-primary" @click="manual_data.agents.push({ name: '', contact: '', email: '', role: '' })">
                        <feather-icon icon="PlusCircleIcon" />
                      </b-button>
                    </div>

                    <template v-for="(agent, i) of manual_data.agents">
                      <div :key="i" class="d-flex align-items-center justify-content-between">
                        <b-form-input v-model="manual_data.agents[i].name" placeholder="Enter Agent Name" class="mr-1 mb-1 w-100"></b-form-input>
                        <b-form-input v-model="manual_data.agents[i].contact" placeholder="Enter Agent Mobile" class="mr-1 mb-1 w-100"></b-form-input>
                        <b-form-input v-model="manual_data.agents[i].email" placeholder="Enter Agent Email" class="mb-1 w-100"></b-form-input>
                        <b-button variant="flat-danger" @click="manual_data.agents.splice(i, 1)" class="mb-1">
                          <feather-icon icon="MinusCircleIcon" />
                        </b-button>
                      </div>
                    </template>
                  </b-col>
                </template>

                <!-- Select fields -->
                <template v-else>
                  <b-col cols="12" class="mb-1 d-flex align-items-baseline justify-content-between">
                    <label class="mr-2">{{ manual_fields[k].label }}</label>
                    <b-form-input v-model="manual_data[k]" :placeholder="'Enter ' + k" class="width-70" :disabled="manual_fields[k].disabled"></b-form-input>
                  </b-col>
                </template>
              </template>
            </b-row>
          </template>
          <b-row>
            <b-col cols="12" class="mb-1">
              <b-button variant="flat-danger" class="float-right" size="sm" @click="reset()" :disabled="busy">
                <feather-icon icon="XSquareIcon" class="mr-50" />
                <span class="align-middle">Reset</span>
              </b-button>
              <!-- user save -->
              <template v-if="['user_search_and_add'].includes(page) && Object.keys(listing).length">
                <b-button
                  variant="flat-primary"
                  class="float-right"
                  size="sm"
                  @click="user_save()"
                  :disabled="busy || !listing_data || !listing_data['address']"
                >
                  <feather-icon icon="SaveIcon" class="mr-50" />
                  <span class="align-middle">{{ newlisting ? "Save Listing" : "Update" }}</span>
                </b-button>
              </template>
              <!-- admin save -->
              <template v-if="['stocklist'].includes(page)">
                <b-button variant="flat-primary" class="float-right" size="sm" @click="save()" :disabled="busy">
                  <feather-icon icon="SaveIcon" class="mr-50" />
                  <span class="align-middle">{{ newlisting ? "Save As" : "Update" }} Listing</span>
                </b-button>
              </template>

              <b-button v-if="show_search_button" variant="flat-primary" class="float-right" size="sm" @click="get_data()" :disabled="busy">
                <feather-icon v-if="!busy" icon="DownloadCloudIcon" class="mr-50" />
                <b-spinner v-if="busy" small type="grow" class="mr-50"></b-spinner>
                <span class="align-middle">{{ busy ? "Loading..." : "Search" }}</span>
              </b-button>
            </b-col>
          </b-row>
          <!-- Manual Data form Section End-->

          <!-- Custom / manual view Listing Data Section Start-->
          <b-table-simple v-if="listing_manual_data && Object.keys(listing_manual_data).length" hover small caption-top responsive>
            <b-thead head-variant="dark">
              <b-tr>
                <b-th class="text-center" colspan="2">Custom / Correction Data</b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <template>
                <b-tr v-for="(value, key) of listing_manual_data" :key="key">
                  <b-th class="capitalize-text">{{ map_fields(key) | format_fields }}</b-th>
                  <b-td class="text-left">{{ value }}</b-td>
                </b-tr>
              </template>
            </b-tbody>
          </b-table-simple>
          <!-- Custom Listing Data Section End-->

          <!-- Listing data Section -->
          <b-row>
            <b-col cols="12" md="8" class="pr-0">
              <b-table-simple hover small caption-top responsive>
                <b-thead head-variant="dark">
                  <b-tr>
                    <b-th class="text-center" colspan="2">Listing information</b-th>
                  </b-tr>
                </b-thead>
                <b-tbody>
                  <template v-if="listing_data && Object.keys(listing_data).length">
                    <template v-for="key of Object.keys(listing_data)">
                      <b-tr :key="key" v-if="!exclude_fields.includes(key)">
                        <b-th class="capitalize-text">{{ map_fields(key) | format_fields }}</b-th>
                        <b-td class="text-left">
                          <div v-if="link_fields.includes(key)" @click="link_clicked(listing_data[key])"><feather-icon icon="Link2Icon" /></div>
                          <div v-else>{{ format_values(key, listing_data[key]) }}</div>
                        </b-td>
                      </b-tr>
                    </template>
                  </template>
                  <template v-else>
                    <b-tr>
                      <b-td class="text-center" colspan="2">No Source Data Found</b-td>
                    </b-tr>
                  </template>
                </b-tbody>
              </b-table-simple>
            </b-col>
            <b-col cols="12" md="4" class="pl-0">
              <b-table-simple hover small caption-top responsive>
                <b-thead head-variant="dark">
                  <b-tr>
                    <b-th class="text-center" colspan="1">Links</b-th>
                  </b-tr>
                </b-thead>
                <b-tbody>
                  <template v-if="Object.keys(listing_data).length">
                    <!-- independent links -->
                    <b-tr v-if="listing_data && listing_data.listing_web_address">
                      <b-th class="link-th" @click="link_clicked(listing_data.listing_web_address)">
                        <feather-icon icon="Link2Icon" />
                        Source
                      </b-th>
                    </b-tr>

                    <b-tr v-if="listing_data && listing_data.address">
                      <b-th class="link-th" @click="link_clicked('https://www.google.com/search?q=' + listing_data.address)">
                        <feather-icon icon="SearchIcon" />
                        Search Google
                      </b-th>
                    </b-tr>

                    <!-- next link -->
                    <b-overlay
                      class="exclude-filters-overlay-background"
                      :show="!permissions.property_data.includes('council_information')"
                      variant="transparent"
                      opacity="0.7"
                      blur="2.5px"
                    >
                      <b-tr v-if="property_data && Object.keys(property_data).length && property_data.lotplan && listing_data.council == 'brisbane_council'">
                        <b-th class="link-th" @click="link_clicked(`https://cityplan.brisbane.qld.gov.au/eplan/property/${property_data.lotplan}/0/209?_t=property`)">
                          <feather-icon icon="Link2Icon" />
                          Council Lookup
                        </b-th>
                      </b-tr>
                    </b-overlay>

                    <!-- next link -->
                    <b-overlay
                      class="exclude-filters-overlay-background"
                      :show="!permissions.property_data.includes('development_approvals')"
                      variant="transparent"
                      opacity="0.7"
                      blur="2.5px"
                    >
                      <b-tr v-if="property_data && Object.keys(property_data).length && property_data.lot_id && listing_data.council == 'brisbane_council'">
                        <b-th
                          class="link-th"
                          @click="link_clicked('https://developmenti.brisbane.qld.gov.au/Home/FilterDirect?filters=LandNumber=' + property_data.lot_id)"
                        >
                          <feather-icon icon="Link2Icon" />
                          Developmenti
                        </b-th>
                      </b-tr>
                    </b-overlay>

                    <b-overlay
                      class="exclude-filters-overlay-background"
                      :show="!permissions.property_data.includes('water')"
                      variant="transparent"
                      opacity="0.7"
                      blur="2.5px"
                    >
                      <b-tr v-if="listing_data && listing_data.address && listing_data.council == 'brisbane_council'">
                        <b-th
                          class="link-th"
                          @click="
                            link_clicked(
                              'https://quu.maps.arcgis.com/apps/webappviewer/index.html?id=3d33e3db46894d46a9cffca08eae68b9&find=' + listing_data.address
                            )
                          "
                        >
                          <feather-icon icon="Link2Icon" />
                          Water & Sewer
                        </b-th>
                      </b-tr>
                    </b-overlay>

                    <b-overlay
                      class="exclude-filters-overlay-background"
                      :show="!permissions.property_data.includes('stormwater')"
                      variant="transparent"
                      opacity="0.7"
                      blur="2.5px"
                    >
                      <b-tr v-if="listing_data && listing_data.address && listing_data.council == 'brisbane_council'">
                        <b-th
                          class="link-th"
                          @click="
                            link_clicked('https://www.arcgis.com/apps/webappviewer/index.html?id=0613d53beac44a088731063bf7fe64ee&find=' + listing_data.address)
                          "
                        >
                          <feather-icon icon="Link2Icon" />
                          Stormwater
                        </b-th>
                      </b-tr>
                    </b-overlay>
                    <b-overlay
                      class="exclude-filters-overlay-background"
                      :show="!permissions.property_data.includes('electrical')"
                      variant="transparent"
                      opacity="0.7"
                      blur="2.5px"
                    >
                      <b-tr v-if="listing_data && listing_data.address">
                        <b-th
                          class="link-th"
                          @click="
                            link_clicked(
                              'https://ergon.maps.arcgis.com/apps/webappviewer/index.html?id=5a53f6f37db84158930f9909e4d30286&find=' + listing_data.address
                            )
                          "
                        >
                          <feather-icon icon="Link2Icon" />
                          Electrical
                        </b-th>
                      </b-tr>
                    </b-overlay>
                  </template>
                  <template v-else>
                    <b-tr>
                      <b-td class="text-center" colspan="2">No Links Found</b-td>
                    </b-tr>
                  </template>
                </b-tbody>
              </b-table-simple>
            </b-col>
          </b-row>

          <!-- Map Section Start -->
          <b-row>
            <b-col cols="12">
              <iframe
                v-if="listing_data && listing_data.address"
                width="100%"
                height="250"
                style="border: 0"
                loading="lazy"
                allowfullscreen
                referrerpolicy="no-referrer-when-downgrade"
                :src="'https://www.google.com/maps/embed/v1/place?key=AIzaSyAVxI1NtkgyDjG9RniT8Mymq63x4E7jdi4&q=' + encodeURIComponent(listing_data.address)"
              ></iframe>
            </b-col>
          </b-row>
          <!-- Map Section End -->

          <!-- Portal Check Section -->
          <template v-if="is_portal_section_allowed()">
            <b-table-simple hover small caption-top responsive>
              <b-thead head-variant="dark">
                <b-tr>
                  <b-th class="text-center" colspan="2">Portal Check</b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <template v-if="domain_data && Object.keys(domain_data).length && domain_data['id']">
                  <template v-for="(value, key) of domain_data">
                    <!-- <b-tr v-if="domain_include_fields.includes(key)" :key="key"> -->
                    <b-tr v-if="domain_include_fields.includes(key)" :key="key">
                      <b-th class="capitalize-text">{{ map_fields(key) | format_fields }}</b-th>

                      <b-td v-if="type(value) !== 'object'" class="text-left">{{ value }}</b-td>
                      <b-td v-else class="text-left">{{ value.join(", ") }}</b-td>
                      <!-- <b-td v-else class="text-left">
                      <span v-for="(v, i) of value" :key="'pc' + i">
                        {{ v }}
                      </span>
                    </b-td> -->
                    </b-tr>
                  </template>
                </template>
                <template v-else>
                  <b-tr>
                    <b-td class="text-center" colspan="2">No Portal Data Found</b-td>
                  </b-tr>
                </template>
              </b-tbody>
            </b-table-simple>
          </template>

          <!-- Google Check Section -->
          <b-table-simple hover small caption-top responsive>
            <b-thead head-variant="dark">
              <b-tr>
                <b-th class="text-center" colspan="2">AI Market Check</b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <template v-if="google_data && Object.keys(google_data).length && google_data['last_checked']">
                <b-tr v-for="(value, key) of google_data" :key="key">
                  <b-th class="capitalize-text">{{ map_fields(key) | format_fields }}</b-th>
                  <b-td class="text-left">
                    <div v-if="link_fields.includes(key) && value" @click="link_clicked(value)"><feather-icon icon="Link2Icon" /></div>
                    <div v-else>{{ format_values(key, value) }}</div>
                  </b-td>
                </b-tr>
              </template>
              <template v-else>
                <b-tr>
                  <b-td class="text-center" colspan="2">No Google Data Found</b-td>
                </b-tr>
              </template>
            </b-tbody>
          </b-table-simple>

          <!-- Property information table -->
          <b-overlay
            class="exclude-filters-overlay-background"
            :show="!permissions.sidebar_sections.includes('council_information')"
            variant="transparent"
            opacity="1.0"
            blur="2.5px"
          >
            <b-table-simple hover small caption-top responsive>
              <b-thead head-variant="dark">
                <b-tr>
                  <b-th class="text-center" colspan="2">Property Information</b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <template v-if="property_data && Object.keys(property_data).length">
                  <template v-for="(value, key) of property_data">
                    <template v-if="!exclude_fields.includes(key)">
                      <!-- if array -->
                      <template v-if="Array.isArray(value)">
                        <b-tr v-for="(v, i) of value" :key="'f' + i">
                          <b-th class="capitalize-text">{{ map_fields(key) + " " + (i + 1) }}</b-th>
                          <b-td class="text-left">{{ format_values(key, v) }}</b-td>
                        </b-tr>
                      </template>
                      <!-- if not array -->
                      <template v-else>
                        <b-tr :key="key">
                          <b-th class="capitalize-text">{{ map_fields(key) | format_fields }}</b-th>
                          <b-td class="text-left">{{ format_values(key, value) }}</b-td>
                        </b-tr>
                      </template>
                    </template>
                  </template>
                </template>
                <template v-else>
                  <b-tr>
                    <b-td class="text-center" colspan="2">No Property Data Found</b-td>
                  </b-tr>
                </template>
              </b-tbody>
            </b-table-simple>
          </b-overlay>

          <!-- Contact information table -->
          <template v-if="is_contact_section_allowed()">
            <b-table-simple hover small caption-top responsive>
              <b-thead head-variant="dark">
                <b-tr>
                  <b-th class="text-center" colspan="2">Contact Information</b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <template v-if="contact_data && Object.keys(contact_data).length">
                  <template v-for="(agent, index) of contact_data.agents">
                    <b-tr v-if="index" :key="'div' + index" variant="primary">
                      <b-td style="border: 0px !important" colspan="2"></b-td>
                    </b-tr>
                    <template v-for="(value, key) of agent">
                      <b-tr v-if="!exclude_fields.includes(key)" :key="key + index">
                        <b-th class="capitalize-text">{{ key }}</b-th>
                        <b-td class="text-left">{{ value }}</b-td>
                      </b-tr>
                    </template>
                  </template>
                </template>
                <template v-else>
                  <b-tr>
                    <b-td class="text-center" colspan="2">No Contact Information Found For This Listing</b-td>
                  </b-tr>
                </template>
              </b-tbody>
            </b-table-simple>
          </template>

          <!-- Flags table -->
          <b-overlay
            class="exclude-filters-overlay-background"
            :show="!permissions.sidebar_sections.includes('council_flags')"
            :variant="'transparent'"
            :opacity="1.0"
            :blur="'2px'"
            rounded="sm"
            :spinner-variant="null"
          >
            <b-table-simple hover small caption-top responsive width="100%">
              <b-thead head-variant="dark">
                <!-- <b-tr>
                <b-th class="text-center" colspan="3">Property Flags</b-th>
              </b-tr> -->
                <b-tr>
                  <b-th class="text-left w-40">Flag Group</b-th>
                  <b-th class="text-left w-30">
                    Flag
                    <span class="small-text" style="margin-left: 2px; margin-bottom: 4px">(Hover for details)</span>
                  </b-th>
                  <b-th class="text-center w-30">
                    <span>Plan Reference</span>
                    <feather-icon icon="ExternalLinkIcon" small style="margin-left: 2px; margin-bottom: 4px" />
                  </b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <template v-if="flags_data && flags_data.length">
                  <template v-for="(flag, index) of flags_data">
                    <template v-if="!exclude_flags.includes(flag.flag)">
                      <b-tr :key="'tfaa' + index">
                        <b-td class="text-left">{{ flag["flagType"] }}</b-td>
                        <b-td class="text-left">
                          <div
                            v-b-tooltip:hover.viewport="{
                              title: '<div>Code: ' + flag['flag'] + '</div>' + flag['reportDescription'],
                              html: true,
                              trigger: 'hover',
                              placement: 'top',
                              variant: 'primary',
                              delay: 100,
                              customClass: 'flags-tooltip-container',
                            }"
                          >
                            <!-- v-b-tooltip:hover.viewport.d500.top.v-primary.title.html="'<div>Title: ' + flag['ReportTitle'] + '</div><br>' + flag['ReportDescription']"> -->
                            <span style="font-size: 0.9em">{{ flag["reportTitle"] }}</span>
                            <!-- <span class="px-1">|</span>
                          <span>{{ flag["ReportTitle"] }}</span> -->
                          </div>
                        </b-td>
                        <b-td class="text-center">{{ flag["planSection"] }}</b-td>
                      </b-tr>
                    </template>
                  </template>
                </template>
                <template v-else>
                  <b-tr>
                    <b-td class="text-center" colspan="3">{{ flags_request ? "Loading Flag Data..." : "No Flags Found For This Property" }}</b-td>
                  </b-tr>
                </template>
              </b-tbody>
            </b-table-simple>
          </b-overlay>
        </b-card-body>
      </b-card>
    </template>
  </b-sidebar>
</template>

<script>
import moment from "moment";
import {
  BSidebar,
  BCard,
  BBadge,
  BCardBody,
  BButton,
  BCardTitle,
  BCardHeader,
  BTableSimple,
  BThead,
  BTbody,
  BTr,
  BTh,
  BTd,
  BCol,
  BTfoot,
  BLink,
  VBTooltip,
  BRow,
  BFormSelect,
  BFormInput,
  BSpinner,
  BAlert,
  BOverlay,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { VueAutosuggest } from "vue-autosuggest";
import vSelect from "vue-select";

export default {
  name: "ListingUpdateSdebar",
  components: {
    BLink,
    BTbody,
    BTfoot,
    BThead,
    BTr,
    BTh,
    BTd,
    BCol,
    BSidebar,
    BCard,
    BBadge,
    BCardBody,
    BButton,
    BCardTitle,
    BCardHeader,
    BTableSimple,
    ToastificationContent,
    VBTooltip,
    BRow,
    BFormSelect,
    BFormInput,
    BSpinner,
    VueAutosuggest,
    vSelect,
    BAlert,
    BOverlay,
  },
  props: ["listing", "sidebar", "page"],
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      exclude_fields: [
        "listing_id",
        "property",
        "lotplan",
        "role",
        "council",
        "domain_data",
        "hide",
        "favourite",
        "hidden",
        "frontages",
        "viewed",
        "pid",
        "premarks",
        "listing_web_address",
        "favourite",
        "hidden",
        "viewed",
        "lat",
        "lon",
        "flags",
        // new exclude fields
        "listing",
        "agents",
        "state",
        "street",
        "suburb",
        "postal_code",
        "id",
        "public",
        "createdAt",
        "updatedAt",
      ],
      exclude_domain_fields: ["photos"],
      domain_include_fields: [
        "status",
        "last_checked",
        "portal",
        "canonicalUrl",
        "address",
        "bathrooms",
        "bedrooms",
        "carSpaces",
        "created",
        "features",
        "flatNumber",
        "isResidential",
        "onMarketTypes",
        "areaSize",
        "propertyCategory",
        "status",
      ],
      link_fields: ["listing_web_address", "website", "domain", "rea"],
      column_mapping: {
        status_date: "Last Update",
        property_type_other: "Category",
        listing_web_address: "Source",
        area: "Area (SQM)",
        frontages: "frontage",
        rea: "REA",
      },
      value_formatters: {
        area: (v) => (v ? v.toFixed(1) : v),
        max_frontage: (v) => (v ? v.toFixed(1) : v),
        fall: (v) => parseFloat(v).toFixed(1),
        frontages: (v) => (v ? v.toFixed(1) : v),
        zone: (v) => (v ? v.replace("QPP-", "") : v),
        time_on_market: (v) => (v ? moment().diff(moment(v, "YYYY-MM-DD"), "days") + " days" : v),
      },

      // tables data
      contact_section_fields: ["agents"],
      property_section_fields: ["property"],
      domain_section_fields: ["domain_data"],
      google_section_fields: ["google_data"],
      manual_section_fields: ["manual_data"],

      listing_data: {},
      property_data: {},
      contact_data: {},
      domain_data: {},
      google_data: {},
      flags_data: [],
      listing_manual_data: {},

      // flags daa fields
      exclude_flags: ["Grid"],
      exclude_flag_fields: [],
      flag_tooltip: ["ReportDescription"],
      flags_request: false,

      // manual fields section
      manual_fields: {
        address: { label: "Address*", presaved: false, input_type: "suggest", multiple: false, fdata: [], fdata_field: "", fdata_value: "", disabled: false },
        status: { label: "Status*", presaved: true, multiple: false, fdata: "status_update_sidebar", fdata_field: "status", fdata_value: "", disabled: false },
        price: { label: "Price", presaved: false, multiple: false, fdata: "", fdata_field: "", fdata_value: "", disabled: false },
        property_type: {
          label: "Type",
          presaved: true,
          multiple: false,
          fdata: "property_type",
          fdata_field: "property_type",
          fdata_value: "",
          disabled: false,
        },
        property_type_other: {
          label: "Category",
          presaved: true,
          multiple: false,
          fdata: "property_type_other",
          fdata_field: "property_type_other",
          fdata_value: "",
          disabled: false,
        },
        comments: { label: "Comments", presaved: false, multiple: false, fdata: "", fdata_field: "", fdata_value: "", disabled: false },
        strategy: { label: "Strategy", presaved: false, multiple: false, fdata: "", fdata_field: "", fdata_value: "", disabled: false },
        agents: { label: "Agents", input_type: "custom_agent", presaved: false, multiple: false, fdata: "", fdata_field: "", fdata_value: "", disabled: false },
        status_date: { label: "Status Date", presaved: false, multiple: false, fdata: "", fdata_field: "", fdata_value: "", disabled: false },
        hush: { label: "Hush Hush", presaved: false, multiple: false, fdata: "", fdata_field: "", fdata_value: "", disabled: false },
        // extra fields
        lotsize: { label: "Lot Size", presaved: false, multiple: false, fdata: "", fdata_field: "", fdata_value: "", disabled: false },
        zone: { label: "Zone", presaved: true, multiple: false, fdata: "zones", fdata_field: "zone", fdata_value: "", disabled: false },
        frontage: { label: "Frontage", presaved: false, multiple: false, fdata: "", fdata_field: "", fdata_value: "", disabled: false },
        depth: { label: "Depth", presaved: false, multiple: false, fdata: "", fdata_field: "", fdata_value: "", disabled: false },
        slope: { label: "Slope", presaved: false, multiple: false, fdata: "", fdata_field: "", fdata_value: "", disabled: false },
        fall: { label: "Fall", presaved: false, multiple: false, fdata: "", fdata_field: "", fdata_value: "", disabled: false },
        public: { label: "Comments", presaved: false, multiple: false, fdata: "", fdata_field: "", fdata_value: "", disabled: false },
      },

      manual_data: {
        address: "",
        status: "",
        price: "",
        property_type: "",
        property_type_other: "",
        // property fields
        lotsize: null,
        zone: null,
        frontage: null,
        depth: null,
        slope: null,
        fall: null,

        // extra fields
        strategy: "",
        comments: "",

        // agents
        agents: [],
      },
      allowed_manual_data_fields: {
        no_listing: ["address"],
        user_search_and_add: ["address", "status", "price", "property_type", "comments"],
        admin_search_and_add: ["address", "status", "price", "property_type", "comments", "agents", "status_date", "hush"],
        managelistings: ["address", "status", "price", "property_type", "comments", "agents", "status_date", "hush"],
        stocklist: [
          "address",
          "status",
          "price",
          "property_type",
          "comments",
          "agents",
          "property_type_other",
          "strategy",
          // extra fields
          "lotsize",
          "zone",
          "frontage",
          "depth",
          "slope",
          "fall",
          "public",
        ],
      },
      busy: false,

      // listing update
      newlisting: true,
      local_storage_keyname: "listings_filter_data",
      filter_data: null,
      show_save_alert: true,
      show_comments_alert: true,
      show_search_button: true,
      exclude_portal_section: ["user_search_and_add"],
      exclude_contact_section: ["user_search_and_add"],
      permissions: null,
    };
  },
  watch: {
    async sidebar(newval) {
      if (!newval) return;
      this.newlisting = this.listing && this.listing.id ? false : true;

      await this.initialize();
      this.set_defaults();
      this.set_defaults_md();
      this.set_sidebar_content(this.listing);
      this.get_flags();
    },
  },
  async mounted() {
    await this.initialize();
    this.permissions = await this.$store.dispatch("get_permissions");
  },
  methods: {
    async initialize() {
      try {
        await this.get_filter_data();

        // setting values for editable mode
        if (this.listing && Object.keys(this.listing).length) {
          this.show_save_alert = false;
          this.show_comments_alert = false;
          this.show_search_button = true;
          this.manual_fields.address.disabled = true;
        }
        // setting values for search only mode
        else {
          this.show_save_alert = true;
          this.show_comments_alert = true;
          this.show_search_button = true;
          this.manual_fields.address.disabled = false;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async get_filter_data() {
      try {
        let yesterday = moment().subtract(2, "days").format("YYYY-MM-DD");
        let filter_data = localStorage.getItem(this.local_storage_keyname);
        filter_data = JSON.parse(filter_data);

        //   checking if valid filters exist in local storage & not 2x days old
        if (filter_data && filter_data.date > yesterday) this.filter_data = filter_data;
        else {
          //   fetching fresh filter data if not found in local storage || 1x day old
          let res = await this.$axios.get(`${this.$API_BASE_LISTINGS}/getlistingsfilterdata`, { filters: [], options: this.options });
          if (!res.data.success) throw new Error("Could not fetch filter data - check your internet connection");
          this.filter_data = res.data.filter_data;
          // setting filter data for component & local storage
          localStorage.setItem(this.local_storage_keyname, JSON.stringify(this.filter_data));
        }
        // this.showToast("info", "Filter Data Updated");
      } catch (error) {
        console.log(error);
        localStorage.removeItem(this.local_storage_keyname);
        this.showToast("danger", "Error While Updating Filter Data");
      }
    },
    async get_data() {
      try {
        if (!this.manual_data.address) throw new Error("Invalid address");
        let a = this.manual_data.address;
        let ac = a.split(",");
        if (ac.length < 4) throw new Error("Invalid address - address should be in format 'street address, suburb, state, postcode'");
        this.busy = true;
        this.set_defaults();
        let res = await this.$axios.post(`${this.$API_BASE_LISTINGS}/fetchdatabyaddress`, {
          listing: { address: a, street: ac[0], suburb: ac[1], state: ac[2], postal_code: ac[3] },
          save_listing: false,
        });
        // console.log(res.data);
        this.busy = false;

        if (!res.data.success) throw new Error(res.data.message);
        this.set_sidebar_content(res.data.listing);
        this.flags_data = res.data.listing.flags;

        if (res.data.saved) {
          this.showToast("info", "Info", "Result saved");
          this.$emit("refresh-listing-table", true);
        }

        if (res.data.duplicate_user_listing) this.showToast("warning", "Info", "Result not saved - Duplicate found");
      } catch (error) {
        this.busy = false;
        console.log(error);
        this.showToast("danger", "Error", error.message ? error.message : "Error while trying to fetch property data");
      }
    },
    async save() {
      try {
        let listing = {};
        // handle if new listing
        if (this.newlisting) {
          if (!this.manual_data.address || !this.manual_data.status) throw new Error("Minimum Address & Status fields required");
          // validating address
          let ac = this.manual_data.address.split(",");
          if (ac.length < 4) throw new Error("Invalid address - address should be in format 'street address, suburb, state, postcode'");

          // processing listing fields
          listing = { ...this.manual_data };
          listing.street = ac[0];
          listing.suburb = ac[1];
          listing.state = ac[2];
          listing.postal_code = ac[3];
        }
        // handle if existing listing
        else {
          let a = {};
          if (this.manual_data && this.manual_data.address) {
            let ac = this.manual_data.address.split(",");
            if (ac.length < 4) throw new Error("Invalid manual address - address should be in format 'street address, suburb, state, postcode'");
            a.street = ac[0];
            a.suburb = ac[1];
            a.state = ac[2];
            a.postal_code = ac[3];
          }
          listing = { ...this.manual_data };
          listing = { ...listing, ...a };
          listing.id = this.listing.id;
        }

        // listing.property = Object.keys(this.property_data).length ? this.property_data : null;
        listing.council = this.listing_data.council || null;
        listing.domain_data = this.domain_data;
        listing.google_data = this.google_data;
        listing.pid = this.listing_data && this.listing_data.pid ? this.listing_data.pid : null;
        listing.premarks = this.listing_data && this.listing_data.premarks ? this.listing_data.premarks : null;
        for (let [k, v] of Object.entries(listing)) if (typeof v == "string") listing[k] = v.trim();

        console.log("Listing: ", listing);
        console.log("New Listing", this.newlisting);
        // busy
        this.busy = true;
        // send request
        // console.log(listing);
        let res = await this.$axios.post(`${this.$API_BASE_LISTINGS}/saveorupdatelistingstock`, {
          listing,
          newlisting: this.newlisting,
        });
        this.busy = false;

        // handle error
        if (!res.data.success) throw new Error(res.data.message);
        // handle success response
        this.showToast("success", "Success", `Listing ${this.newlisting ? "saved" : "updated"} successfully`);
        this.$emit("refresh-listing-table");
        this.$emit("toggle_sidebar", false);
      } catch (error) {
        this.busy = false;
        console.log(error);
        this.showToast("danger", "Error", error.message ? error.message : "Error while trying save listing");
      }
    },
    async user_save() {
      try {
        // return console.log("this.manual_data", this.manual_data);
        let listing = {};
        // handle if new listing
        if (this.newlisting) {
          if (!this.manual_data.address || !this.manual_data.status) throw new Error("Minimum Address & Status fields required");
          // validating address
          let ac = this.manual_data.address.split(",");
          if (ac.length < 4) throw new Error("Invalid address - address should be in format 'street address, suburb, state, postcode'");

          // processing listing fields
          listing = { ...this.manual_data };
          listing.street = ac[0];
          listing.suburb = ac[1];
          listing.state = ac[2];
          listing.postal_code = ac[3];
        }
        // handle if existing listing
        else {
          let a = {};
          if (this.manual_data && this.manual_data.address) {
            let ac = this.manual_data.address.split(",");
            if (ac.length < 4) throw new Error("Invalid manual address - address should be in format 'street address, suburb, state, postcode'");
            a.street = ac[0];
            a.suburb = ac[1];
            a.state = ac[2];
            a.postal_code = ac[3];
          }
          listing.listing_id = this.listing.listing_id;
          listing.manual_data = { ...this.manual_data };
          listing.manual_data = { ...listing.manual_data, ...a };
        }

        // send request
        // console.log(listing);
        this.busy = true;
        let res = await this.$axios.post(`${this.$API_BASE_LISTINGS}/saveorupdateuserlisting`, {
          listing,
          newlisting: this.newlisting,
        });
        this.busy = false;

        // handle error
        if (!res.data.success) throw new Error(res.data.message);
        // handle success response
        this.showToast("success", "Success", `Listing updated successfully`);
        this.$emit("refresh-listing-table");
        this.$emit("toggle_sidebar", false);
      } catch (error) {
        this.busy = false;
        console.log(error);
        this.showToast("danger", "Error", error.message ? error.message : "Error while trying save listing");
      }
    },
    set_sidebar_content(listing) {
      if (!listing) return;
      console.log("new listing: ", this.newlisting);
      // setting manual data fields (form fields)
      this.manual_data.address = listing.address || "";
      if (!this.newlisting) {
        for (let [k, v] of Object.entries(this.manual_data)) this.manual_data[k] = this.listing[k];
        this.manual_data["agents"] = JSON.parse(this.listing["agents"]);
      }

      // setting values for different sidebar sections
      for (let [key, value] of Object.entries(listing)) {
        if (this.domain_section_fields.includes(key)) this.domain_data = value;
        else if (this.manual_section_fields.includes(key)) this.listing_manual_data = value;
        else if (this.google_section_fields.includes(key)) this.google_data = value;
        else if (this.contact_section_fields.includes(key)) this.contact_data[key] = this.json_to_array(value);
        else if (this.property_section_fields.includes(key) && value && value.length) this.property_data = value[0] || {};
        else this.listing_data[key] = value;
      }
    },
    async get_flags() {
      try {
        if (!this.listing || !Object.keys(this.listing).length || !this.listing.property.length || !this.listing.property[0].lotplan) return;
        let listing = {};
        listing.lotplan = this.listing.property[0].lotplan;
        this.flags_request = true;
        let res = await this.$axios.post(`${this.$API_BASE_LISTINGS}/getlistingflags`, { listing });
        this.flags_request = false;

        if (!res.data.success) throw new Error("Flags could not be fetched");
        this.flags_data = res.data.flags;
      } catch (error) {
        this.flags_request = false;
        console.log(error);
        this.showToast("danger", "Error", "Error while trying to fetch flags");
      }
    },
    async getsuggestions(k) {
      try {
        await new Promise((t) => setTimeout(t, 100));
        if (!k || this.manual_data[k].length < 5) return;
        this.busy = true;
        let res = await this.$axios.post(`${this.$API_BASE_LISTINGS}/getsuggestions`, { key: k, address: this.manual_data[k] });
        this.busy = false;
        if (!res.data.success) throw new Error("Test data could not be fetched");
        this.manual_fields[k].fdata = [];
        this.manual_fields[k].fdata = res.data.suggestions;
      } catch (error) {
        this.busy = false;
        console.log(error);
      }
    },
    json_to_array(v) {
      try {
        v = JSON.parse(v);
        return Array.isArray(v) ? v : [v];
      } catch (error) {
        return [];
      }
    },
    reset() {
      if (this.newlisting) {
        this.set_defaults();
      } else {
        this.set_defaults();
        this.set_sidebar_content(this.listing);
        this.get_flags();
      }
    },
    set_defaults() {
      this.listing_data = {};
      this.property_data = {};
      this.flags_data = [];
      this.contact_data = {};
      this.domain_data = {};
      this.google_data = {};
    },
    set_defaults_md() {
      this.manual_data = {
        address: "",
        status: "",
        price: "",
        property_type: "",
        property_type_other: "",
        // property fields
        lotsize: null,
        zone: null,
        frontage: null,
        depth: null,
        slope: null,
        fall: null,
        // extra fields
        strategy: "",
        comments: "",
        agents: [],
      };
    },
    showToast(variant = "info", title = "Info", text = "") {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon: "BellIcon",
          text,
          variant,
        },
      });
    },
    map_fields(key) {
      if (Object.keys(this.column_mapping).includes(key)) key = this.column_mapping[key];
      return key;
    },
    format_values(key, value) {
      if (Object.keys(this.value_formatters).includes(key)) value = this.value_formatters[key](value);
      return value;
    },
    link_clicked(value) {
      if (!value) return;
      value = value.includes("http") ? value : "https://" + value;
      window.open(value);
    },
    type(v) {
      return typeof v;
    },
    sanitize_url(url) {
      return url.replace();
    },
    set_autosuggest_value(s, k) {
      try {
        if (k == "address" && s.item && Object.keys(s.item.addressComponents).length) {
          let ac = s.item.addressComponents;
          this.manual_data[k] = `${ac.streetNumber} ${ac.streetName} ${ac.streetTypeLong}, ${ac.suburb}, ${ac.state}, ${ac.postCode}`;
          this.manual_data[k] = ac.unitNumber ? ac.unitNumber + "/" + this.manual_data[k] : this.manual_data[k];
        } else {
          // requires handling if any other field added for auto suggestion
          this.manual_data[k] = s.item;
        }
      } catch (error) {
        console.log(error);
        this.manual_data[k] = "";
      }
    },
    is_manual_data_field_allowed(key) {
      try {
        // if (!Object.keys(this.listing).length) return this.allowed_manual_data_fields.no_listing.includes(key);
        return this.allowed_manual_data_fields[this.page].includes(key);
      } catch (error) {
        console.log(error);
        return false;
      }
    },
    is_portal_section_allowed() {
      return !this.exclude_portal_section.includes(this.page);
    },
    is_contact_section_allowed() {
      return !this.exclude_contact_section.includes(this.page);
    },
  },
  filters: {
    format_fields(str) {
      return typeof str == "string"
        ? str
            .replace(/_/g, " ")
            .replace(/([A-Z]{1}[a-z]+)/g, " $1")
            .trim()
        : str;
    },
    formatDate: function (date) {
      return moment(date).fromNow();
    },
  },
};
</script>

<style lang="scss">
#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
.capitalize-text {
  text-transform: capitalize;
}
.flags-tooltip-container.tooltip .tooltip-inner {
  max-width: 500px !important;
  width: 400px !important;
}
.small-text {
  font-size: 10px;
}
.width-30 {
  width: 30%;
}
.width-70 {
  width: 70%;
}
.w-160p {
  width: 160px;
}
.w-33 {
  width: 33%;
}
.w-40 {
  width: 40%;
}
.w-30 {
  width: 30%;
}
.w-20 {
  width: 20%;
}
.listing_table_fields {
  border-bottom: 1px solid rgb(224, 221, 221);
}

#autosuggest_address {
  width: 100%;
  padding: 0.5rem;
}

.sidebar-autosuggest ul {
  width: 100%;
  color: rgba(30, 39, 46, 1);
  list-style: none;
  margin: 0;
  padding: 0.5rem 0 0.5rem 0;
}
.sidebar-autosuggest li {
  margin: 0 0 0 0;
  border-radius: 5px;
  padding: 0.75rem 0 0.75rem 0.75rem;
  display: flex;
  align-items: center;
}
.sidebar-autosuggest li:hover {
  cursor: pointer;
}

.autosuggest-container {
  display: flex;
  justify-content: center;
  width: 280px;
}

#autosuggest {
  width: 70%;
  display: block;
}
.sidebar-autosuggest .autosuggest__results-item--highlighted {
  background-color: rgba(80, 140, 253, 0.2);
}
.sidebar-autosuggest .autosuggest__results {
  position: absolute;
  width: 100%;
  overflow-y: auto;
  max-height: 50vh;
  z-index: 999;
  background-color: white;
  border: 1px solid rgb(202, 199, 199);
}
</style>
