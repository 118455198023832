<template>
  <div>
    <!-- Filters -->
    <!-- <basic-filters
      @basic-filters-search="search"
      @basic-filters-changed="(v) => (filters = v)"
      @basic-not-filters-changed="(v) => (not_filters = v)"
      :allowed_filters="allowed_filters"
      :suburb_set_to_use="suburb_set_to_use"
      page="ManageListings"
    /> -->
    <view-sidebar :sidebar="sidebar" :listing="sidebar_listing" @toggle_sidebar="(val) => (sidebar = val)" />
    <update-sidebar
      :sidebar="update_sidebar"
      :listing="update_sidebar_listing"
      @toggle_sidebar="(val) => (update_sidebar = val)"
      @refresh-listing-table="initialize()"
      page="stocklist"
    />
    <permission-sidebar
      :sidebar="permission_sidebar"
      :listing_id="permission_sidebar_listing_id"
      :listing_address="permission_sidebar_listing_address"
      @toggle_sidebar="(val) => (permission_sidebar = val)"
      @refresh-listing-table="initialize()"
      page="stocklist"
    />
    <!-- Table Container Card -->
    <b-card no-body class="mb-0" v-if="permissions">
      <div class="m-2">
        <!-- Table Top -->
        <b-row class="d-flex align-items-baseline justify-content-center">
          <!-- Per Page -->
          <b-col cols="12" md="12" class="d-flex align-items-baseline justify-content-between">
            <div class="mx-1">
              <label>Show</label>
              <v-select
                v-model="options.perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
                style="margin: 8px"
              />
              <label>entries</label>
            </div>

            <div class="d-flex align-items-baseline justify-content-end">
              <b-form-checkbox v-model="show_hidden" class="mb-2">Show Hidden</b-form-checkbox>
              <b-button variant="flat-primary" @click="initialize()">
                <feather-icon icon="RefreshCwIcon" class="mr-25" />
                <span class="align-middle">Refresh</span>
              </b-button>
              <b-button variant="flat-primary" @click="show_update_sidebar({}, true)" v-if="permissions.stocklist.includes('add_listing')">
                <feather-icon icon="PlusIcon" class="mr-25" />
                <span class="align-middle">Add Listing</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="listingtable"
        primary-key="id"
        :items="listings"
        :fields="tableColumns"
        :sort-by.sync="options.sortBy"
        :sort-desc.sync="options.sortDesc"
        :current-page.sync="options.currentPage"
        per-page="0"
        :filter="null"
        :api-url="null"
        show-empty
        class="position-relative"
        responsive
        :empty-text="busy ? 'Loading Data...' : 'No matching records found'"
        sticky-header="80vh"
        hover
        :busy="busy"
        head-variant="light"
        no-sort-reset
        @row-clicked="show_listing_sidebar"
        :tbody-tr-class="rowClass"
      >
        <template #cell(address)="data">
          <div class="d-flex align-items-baseline justify-content-between" style="padding: 10px 20px">
            <div class="pr-1">{{ data.item.address }}</div>
          </div>
        </template>
        <!-- Column: Status -->
        <template #cell(links)="data">
          <div class="d-flex align-items-baseline justify-content-start">
            <feather-icon
              v-if="flag_icon_tooltip(data.item)"
              icon="FlagIcon"
              size="16"
              class="link-icons"
              color="red"
              v-b-tooltip:click.d100.top.v-primary.title="flag_icon_tooltip(data.item)"
            />
            <feather-icon
              icon="CheckCircleIcon"
              size="16"
              class="link-icons"
              :color="domain_icon_color(data.item.domain_data)"
              v-b-tooltip:click.d100.top.v-primary.title="domain_icon_tooltip(data.item.domain_data)"
            />

            <font-awesome-icon
              @click="show_listing_sidebar(data.item)"
              class="link-icons"
              icon="fa-solid fa-circle-info"
              v-b-tooltip:hover.d100.top.v-primary.title="property_data_icon_tooltip(data.item.property.length)"
              :color="data.item.property.length ? '#1F5A94' : 'grey'"
            />
            <font-awesome-icon
              @click.stop="open_link('https://www.google.com/search?q=' + data.item.address)"
              v-b-tooltip:hover.v-primary="{
                customClass: '',
                variant: 'info',
                title: 'Google Search',
                placement: 'topleft',
                trigger: 'hover',
              }"
              color="#1f5a94"
              class="link-icons"
              icon="fa-brands fa-google"
            />
            <font-awesome-icon
              v-b-tooltip:hover.d100.top.v-primary.title="'Visit Source'"
              color="#1f5a94"
              @click.stop="open_link(data.item.listing_web_address)"
              class="link-icons"
              icon="fa-solid fa-arrow-up-right-from-square"
            />
          </div>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown variant="link" no-caret>
            <template #button-content>
              <feather-icon icon="MoreVerticalIcon" size="18" class="align-middle text-body" />
            </template>

            <b-dropdown-item @click.stop="show_update_sidebar(data.item, true)" v-if="permissions.stocklist.includes('edit_listing')">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">Edit Stock Listing</span>
            </b-dropdown-item>
            <!-- <b-dropdown-item @click.stop="admin_toggle_hide(data.item.id)">
              <feather-icon :icon="!data.item.hidden_listing ? 'EyeIcon' : 'EyeOffIcon'" size="16" class="link-icons" />
              <span class="align-middle ml-50">{{ data.item.hide ? "Unhide" : "Hide" }} Listing</span>
            </b-dropdown-item> -->

            <b-dropdown-item @click.stop="admin_toggle_hide(data.item.id)" v-if="permissions.stocklist.includes('hide_listing')">
              <feather-icon :icon="!data.item.hide ? 'EyeIcon' : 'EyeOffIcon'" size="16" class="link-icons" />
              <span class="align-middle ml-50">{{ data.item.hide ? "Unhide" : "Hide" }} Listing</span>
            </b-dropdown-item>

            <b-dropdown-item @click.stop="user_toggle_hide(data.item.id)" v-if="permissions.stocklist.includes('user_hide_listing')">
              <feather-icon :icon="!data.item.hide ? 'EyeIcon' : 'EyeOffIcon'" size="16" class="link-icons" />
              <span class="align-middle ml-50">{{ data.item.hide ? "Unhide" : "Hide" }} Listing</span>
            </b-dropdown-item>

            <b-dropdown-item @click.stop="remove_from_stocklist(data.item.id)" v-if="permissions.stocklist.includes('remove_listing')">
              <feather-icon icon="FolderPlusIcon" size="16" class="link-icons" />
              <span class="align-middle ml-50">Remove</span>
            </b-dropdown-item>

            <b-dropdown-item @click.stop="show_permission_sidebar(data.item.id, data.item.address)" v-if="permissions.stocklist.includes('set_permissions')">
              <feather-icon icon="FolderPlusIcon" size="16" class="link-icons" />
              <span class="align-middle ml-50">Set Permissions</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>

      <!-- pagination section -->
      <div class="mx-2 mb-2">
        <b-row>
          <b-col cols="12" md="4" class="d-flex align-items-center justify-content-start">
            <span class="text-muted">
              Showing {{ (options.currentPage - 1) * options.perPage }} to {{ (options.currentPage - 1) * options.perPage + options.perPage }} of
              {{ total_listings }} entries
            </span>
          </b-col>
          <!-- Pagination -->
          <b-col cols="12" md="8" class="d-flex align-items-center justify-content-start">
            <b-pagination
              v-model="options.currentPage"
              :per-page="options.perPage"
              :total-rows="total_listings"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import moment from "moment";
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  VBTooltip,
  BFormCheckbox,
} from "bootstrap-vue";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import ViewSidebar from "./ViewSidebar.vue";
import UpdateSidebar from "./UpdateSidebar.vue";
import PermissionSidebar from "./PermissionSidebar.vue";
import BasicFilters from "../components/BasicFilters.vue";

export default {
  name: "StockList",
  components: {
    BasicFilters,
    ViewSidebar,
    UpdateSidebar,
    PermissionSidebar,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BMedia,
    vSelect,
    VBTooltip,
    ToastificationContent,
    BFormCheckbox,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      listings: [],
      loading: false,
      tableColumns: [
        {
          key: "address",
          label: "Address",
          sortable: true,
          variant: "warning",
          stickyColumn: true,
          thStyle: { width: "33%" },
          tdClass: "listings-table-td-address",
          thClass: "'listings-table-td-address'",
        },
        {
          key: "zone",
          label: "Zone",
          sortable: false,
          //   formatter: (v, k, item) => (item.property.length ? item.property[0].zone.replace("QPP-", "") : ""),
          //   thStyle: { width: "8%" },
        },
        {
          key: "lotsize",
          label: "Size",
          sortable: false,
          //   formatter: (v, k, item) => (item.property.length ? item.property[0].area.toFixed(1) : ""),
          //   thStyle: { width: "8%" },
        },
        {
          key: "frontage",
          label: "Frontage",
          sortable: true,
          //   formatter: (v, k, item) => (item.property.length && item.property[0].max_frontage ? item.property[0].max_frontage.toFixed(1) : ""),
          // thStyle: { width: "8%" },
        },
        {
          key: "fall",
          label: "Fall",
          sortable: false,
          //   formatter: (v, k, item) => (item.property.length && item.property[0].fall ? parseFloat(item.property[0].fall).toFixed(1) : ""),
          //   thStyle: { width: "8%" },
        },
        {
          key: "price",
          label: "Price",
          sortable: true,
          //   thStyle: { width: "15%" },
        },
        {
          key: "status_date",
          label: "Updated",
          sortable: true,
          //   thStyle: { width: "10%" },
        },

        // {
        //   key: "links",
        //   label: "Links",
        //   thStyle: { width: "5%", "text-align": "center" },
        // },
        {
          key: "actions",
          label: "Actions",
          thStyle: { width: "5%", "text-align": "center" },
        },
      ],

      // table options
      options: {
        currentPage: 1,
        perPage: 50,
        sortBy: "status_date",
        sortDesc: true,
        // filter: null,
        // apiUrl: null,
      },

      default_options: {
        currentPage: 1,
        perPage: 50,
        sortBy: "status_date",
        sortDesc: true,
        // filter: null,
        // apiUrl: null,
      },

      total_listings: 0,
      perPageOptions: [10, 50, 100, 150, 200, 250],
      // pagination: { from: 0, to: 0, of: 0 },
      busy: false,

      // sidebar fields
      sidebar: false,
      sidebar_listing: null,

      // filtrs options
      filters: [],
      not_filters: [],
      allowed_filters: [
        "address",
        "street",
        "suburb",
        "state",
        "postal_code",
        "council",
        "status",
        "property_type",
        "property_type_other",
        "price",
        "city_plan_",
        "length",
        "area",
        "fall",
        "hide",
        "updated",
        "additional_type",
        "property_data",
        "frontages",
      ],
      suburb_set_to_use: "qld_suburbs",

      // favourites & hidden section
      // highlighting
      three_months_ago: moment().subtract(3, "months").format("YYYY-MM-DD"),
      last_two_dates: [0, 1, 2].map((d) => moment().subtract(d, "days").format("YYYY-MM-DD")),
      last_three_months: moment().subtract(2, "months").format("YYYY-MM-DD"),

      // manual update sidebar
      update_sidebar_listing: {},
      update_sidebar: false,

      // admin / team flag fields
      market_status_mapping: {
        on_market: ["for lease", "for sale"],
        off_market: ["sold", "under contract", "on hold / withdrawn", "unknown", "under offer"],
      },

      // PERMISSION SIDEBAR
      permission_sidebar_listing_id: null,
      permission_sidebar_listing_address: null,
      permission_sidebar: false,

      // module actions
      show_hidden: false,

      // module permissions
      permissions: null,
    };
  },

  watch: {
    options: {
      deep: true,
      async handler() {
        await this.load_listings();
      },
    },
  },
  async mounted() {
    try {
      this.initialize();
    } catch (error) {
      console.log(error);
    }
  },
  methods: {
    async initialize() {
      try {
        // changing options would trigger load_listings() by the watcher
        // this.options = Object.assign({}, this.default_options);
        this.permissions = await this.$store.dispatch("get_permissions");
        this.load_listings();
      } catch (error) {
        console.log(error);
      }
    },
    async load_listings() {
      try {
        if (this.busy) return;
        this.busy = true;
        console.log("show_hidden request", this.show_hidden);
        let res = await this.$axios.post(`${this.$API_BASE_LISTINGS}/getstocklist`, {
          filters: this.filters,
          not_filters: this.not_filters,
          options: this.options,
          show_hidden: this.show_hidden,
        });
        this.busy = false;
        console.log("response: ", res.data);
        if (!res.data.success) throw new Error("listings could not be fetched");

        this.listings = res.data.listings;
        this.total_listings = res.data.count;
      } catch (error) {
        this.busy = false;
        console.log(error);
      }
    },
    async search(filters) {
      this.filters = filters;
      this.options.currentPage != 1 ? (this.options.currentPage = 1) : await this.load_listings();
      // await this.load_listings();
    },
    set_filters(filters) {
      this.filters = filters;
      this.not_filters = not_filters;
    },
    show_listing_sidebar(listing) {
      this.sidebar_listing = listing;
      this.sidebar = true;
    },
    show_permission_sidebar(id, address) {
      this.permission_sidebar_listing_id = id;
      this.permission_sidebar_listing_address = address;
      this.permission_sidebar = true;
    },
    open_link(link) {
      window.open(link);
    },
    domain_icon_color(data) {
      if (data && Object.keys(data).length && data["last_checked"]) {
        if (data["status"].toLowerCase() == "onmarket") return "orange";
        if (data["status"].toLowerCase() == "offmarket") return "green";
      }
      return "grey";
    },
    domain_icon_tooltip(data) {
      if (data && Object.keys(data).length && data["last_checked"]) return `Domain Data Available - ${data["status"]}`;
      else return "Domain Data Not Available";
    },
    property_data_icon_tooltip(condition) {
      if (condition) return `Property Data Available`;
      else return "Property Data Not Available";
    },
    flag_icon_tooltip(item) {
      // flag criteria:
      // google.status_date > listing.status_date
      // mismatching status implication, specially when "an on-market property goes off-market"
      // more criteria to be added as per requirement / discussion
      let is_on_market = this.market_status_mapping.on_market.includes(item.status.toLowerCase());
      let is_off_market = this.market_status_mapping.off_market.includes(item.status.toLowerCase());

      // google comparison with lisitng data
      if (item.google_data && item.google_data.status && item.google_data.last_checked) {
        let last_checked_google = item.google_data.last_checked.split(" ")[0];
        if (
          last_checked_google >= item.status_date &&
          ((is_on_market && item.google_data.status.toLowerCase() == "off market") || (is_off_market && item.google_data.status.toLowerCase() == "on market"))
        )
          return "Mismatching Status: AI Market Check";
      }

      // domain comparison with listing data
      if (item.domain_data && item.domain_data.status && item.domain_data.last_checked) {
        let last_checked_domain = item.domain_data.last_checked.split(" ")[0];
        if (
          last_checked_domain >= item.status_date &&
          ((is_on_market && item.domain_data.status.toLowerCase() == "offmarket") || (is_off_market && item.domain_data.status.toLowerCase() == "onmarket"))
        )
          return "Mismatching Status: Domain Market Check";
      }

      // listing data age comparison
      if (is_on_market && item.status_date < this.last_three_months) return "Listing older than 60 days";
    },
    async admin_toggle_hide(id) {
      try {
        console.log("admin_toggle_hide");
        this.busy = true;
        let res = await this.$axios.post(`${this.$API_BASE_LISTINGS}/stocklistingadmintogglehide`, { id });
        this.busy = false;
        if (!res.data.success) throw new Error("listings status could not be updated");

        const index = this.listings.findIndex((l) => l.id == id);
        this.listings[index].hide = !this.listings[index].hide;

        this.showToast("info", "Info", `Listing status Updated`);
      } catch (error) {
        this.busy = false;
        console.log(error);
        this.showToast("danger", "Error", error.message ? error.message : "Listings status could not be updated");
      }
    },
    async user_toggle_hide(id) {
      try {
        console.log("user_toggle_hide");
        this.busy = true;
        let res = await this.$axios.post(`${this.$API_BASE_LISTINGS}/stocklistingusertogglehide`, { id });
        this.busy = false;
        if (!res.data.success) throw new Error("Listings status could not be updated");

        const index = this.listings.findIndex((l) => l.id == id);
        this.listings.splice(index, 1);
        this.total_listings--;

        this.showToast("info", "Info", `Listing status updated`);
      } catch (error) {
        this.busy = false;
        console.log(error);
        this.showToast("danger", "Error", error.message ? error.message : "Listings status could not be updated");
      }
    },
    showToast(variant = "info", title = "Info", text = "") {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon: "BellIcon",
          text,
          variant,
        },
      });
    },
    rowClass(item, type) {
      if (!item || type !== "row" || !item.hide) return;
      else return "table-danger";
    },
    is_old(input_date) {
      return input_date && input_date < this.three_months_ago ? true : false;
    },
    updated_status(l) {
      let text = "";
      if (this.last_two_dates.includes(l.status_date)) {
        if (l.status_date == l.input_date) text = "(New)";
        else text = "(Updated)";
      }
      return text;
    },
    show_update_sidebar(listing, show) {
      console.log(listing);
      this.update_sidebar_listing = listing;
      this.update_sidebar = show;
    },
    async remove_from_stocklist(id) {
      try {
        this.busy = true;
        let res = await this.$axios.post(`${this.$API_BASE_LISTINGS}/removefromstocklist`, { id });
        this.busy = false;
        if (!res.data.success) throw new Error(res.data.message);
        // removing listing from table
        this.listings = this.listings.filter((l) => l.id != id);
        this.total_listings--;
        this.showToast("info", "Info", `Listing Removed`);
      } catch (error) {
        this.busy = false;
        console.log(error);
        this.showToast("danger", "Error", error.message ? error.message : "Listings could not be removed");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.link-icons {
  padding-right: 3px;
  cursor: pointer;
  font-size: 1em;
  font-weight: 800;
}

.small-text {
  font-size: 11px;
  font-weight: bold;
}
.old_listing {
  background-color: #ffd7d7;
}
.is_viewed {
  opacity: 0.5;
}
</style>
