<template>
  <b-sidebar
    id="true"
    width="55%"
    :visible="sidebar"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="(val) => $emit('toggle_sidebar', val)"
  >
    <template>
      <b-card class="card-transaction" no-body>
        <b-card-header>
          <b-card-title>
            Permission
            <small>( {{ listing_address }} )</small>
          </b-card-title>
          <div>
            <b-button variant="flat-primary" class="float-right" size="sm" @click="() => $emit('toggle_sidebar', false)">
              <feather-icon icon="XIcon" class="mr-50" />
              <span class="align-middle">Close</span>
            </b-button>
          </div>
        </b-card-header>
        <b-overlay :show="busy" variant="transparent" opacity="0.7" blur="2.5px">
          <b-card-body v-if="permissions">
            <template>
              <!-- <template>
              <b-alert variant="success" dismissible fade :show="show_save_alert" class="mb-1" @dismissed="show_save_alert = false">
                <div class="alert-body pr-2">
                  <span>Searched properties will automatically be saved for valid addresses & shown in table as search history</span>
                </div>
              </b-alert>
              <b-alert variant="primary" dismissible fade :show="show_comments_alert" class="mb-1" @dismissed="show_comments_alert = false">
                <div class="alert-body pr-2">
                  <span>Additional data & comments can be added to a listing by adding it to favourites in search history table</span>
                </div>
              </b-alert>
            </template> -->
              <b-row>
                <template>
                  <b-col cols="12" class="mb-1">
                    <b-form-checkbox v-model="public_listing" class="mb-2" @change="set_public_listing()">
                      {{ public_listing ? "Public" : "Private" }}
                    </b-form-checkbox>
                  </b-col>
                </template>
              </b-row>

              <!-- Manual Data form Section Start-->
              <b-row v-if="!public_listing">
                <template>
                  <b-col cols="12" class="mb-1">
                    <label class="mr-2">Select User</label>
                    <vue-autosuggest
                      :input-props="{
                        id: 'autosuggest_select_user',
                        class: 'form-control',
                        placeholder: 'Type to get suggestions',
                        disabled: false,
                      }"
                      :suggestions="[{ data: user_suggestions }]"
                      @input="getsuggestions"
                      @selected="set_autosuggest_value"
                      :get-suggestion-value="(i) => i.item.email"
                      :limit="20"
                      component-attr-class-autosuggest-results-container="sidebar-autosuggest"
                    >
                      <template slot-scope="{ suggestion }">
                        <span>{{ suggestion.item.name }} ({{ suggestion.item.email }})</span>
                      </template>
                    </vue-autosuggest>
                  </b-col>
                </template>
              </b-row>
            </template>

            <b-row v-if="!public_listing">
              <b-col cols="12" class="mb-1">
                <!-- <b-button variant="flat-danger" class="float-right" size="sm" @click="initialize()" :disabled="busy">
                <feather-icon icon="XSquareIcon" class="mr-50" />
                <span class="align-middle">Reset</span>
              </b-button> -->

                <b-button variant="flat-primary" class="float-right" size="sm" @click="add_user_permission()" :disabled="busy || !selected_user">
                  <feather-icon v-if="!busy" icon="SaveIcon" class="mr-50" />
                  <b-spinner v-else small type="grow" class="mr-50"></b-spinner>
                  <span class="align-middle">{{ busy ? "Loading..." : "Add User" }}</span>
                </b-button>
              </b-col>
            </b-row>
            <!-- Manual Data form Section End-->

            <!-- Permitted users -->
            <b-table-simple v-if="!public_listing" hover small caption-top responsive>
              <b-thead head-variant="dark">
                <b-tr>
                  <b-th class="text-center" colspan="2">Permitted Users</b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <template v-if="permitted_users.length">
                  <b-tr v-for="(user, key) of permitted_users" :key="key">
                    <b-th>{{ user.email }}</b-th>
                    <b-td class="text-left">
                      <b-button variant="flat-primary" class="float-right" size="sm" @click="remove_user_permission(user.user_id)">
                        <span class="align-middle">Remove</span>
                      </b-button>
                    </b-td>
                  </b-tr>
                  <!-- <b-tr>
                  <b-th class="capitalize-text">User1</b-th>
                  <b-td class="text-left">Action</b-td>
                </b-tr> -->
                </template>
                <template v-else>
                  <b-tr>
                    <b-td class="text-center" colspan="2">No permitted users found</b-td>
                  </b-tr>
                </template>
              </b-tbody>
            </b-table-simple>
          </b-card-body>
        </b-overlay>
      </b-card>
    </template>
  </b-sidebar>
</template>

<script>
import moment from "moment";
import {
  BSidebar,
  BCard,
  BBadge,
  BCardBody,
  BButton,
  BCardTitle,
  BCardHeader,
  BTableSimple,
  BThead,
  BTbody,
  BTr,
  BTh,
  BTd,
  BCol,
  BTfoot,
  BLink,
  VBTooltip,
  BRow,
  BFormSelect,
  BFormInput,
  BSpinner,
  BAlert,
  BOverlay,
  BFormCheckbox,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { VueAutosuggest } from "vue-autosuggest";
import vSelect from "vue-select";

export default {
  name: "ListingUpdateSdebar",
  components: {
    BLink,
    BTbody,
    BTfoot,
    BThead,
    BTr,
    BTh,
    BTd,
    BCol,
    BSidebar,
    BCard,
    BBadge,
    BCardBody,
    BButton,
    BCardTitle,
    BCardHeader,
    BTableSimple,
    ToastificationContent,
    VBTooltip,
    BRow,
    BFormSelect,
    BFormInput,
    BSpinner,
    VueAutosuggest,
    vSelect,
    BAlert,
    BOverlay,
    BFormCheckbox,
  },
  props: ["listing_id", "listing_address", "sidebar", "page"],
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      // listing permissions
      public_listing: true,
      selected_user: null,
      user_suggestions: [],
      permitted_users: [],

      // managemement fields
      busy: false,

      // module & feature permissions
      permissions: null,
    };
  },
  watch: {
    // async public_listing(newval) {
    //   await this.set_public_listing();
    // },
    async sidebar(newval) {
      if (!newval) return;
      this.set_defaults();
      await this.initialize();
    },
  },
  async mounted() {
    this.permissions = await this.$store.dispatch("get_permissions");
  },
  methods: {
    async initialize() {
      try {
        await this.get_data();
      } catch (error) {
        console.log(error);
      }
    },

    async get_data() {
      try {
        if (!this.listing_id) throw new Error("Invalid id");
        this.busy = true;
        let res = await this.$axios.post(`${this.$API_BASE_LISTINGS}/getstocklistingpermissiondata`, {
          id: this.listing_id,
        });
        console.log(res.data);
        this.busy = false;

        if (!res.data.success) throw new Error(res.data.message);
        this.permitted_users = res.data.permitted_users;
        this.public_listing = res.data.public;
      } catch (error) {
        this.busy = false;
        console.log(error);
        this.showToast("danger", "Error", error.message ? error.message : "Error while trying to get permission data");
      }
    },

    async getsuggestions(query) {
      try {
        if (query.length < 3) return;
        this.busy = true;
        let res = await this.$axios.post(`${this.$API_BASE_LISTINGS}/getusersuggestions`, { query });
        this.busy = false;
        if (!res.data.success) throw new Error("Suggestions could not be fecthed");
        this.user_suggestions = res.data.suggestions;
      } catch (error) {
        this.busy = false;
        console.log(error);
      }
    },

    set_autosuggest_value(s) {
      try {
        console.log(s.item);
        if (!s || !s.item) return;
        this.selected_user = s.item.id;
        console.log("Selected user", this.selected_user);
      } catch (error) {
        console.log(error);
        this.selected_user = null;
      }
    },
    async set_public_listing() {
      try {
        this.busy = true;
        let res = await this.$axios.post(`${this.$API_BASE_LISTINGS}/setstocklistingstatus`, { public_listing: this.public_listing, id: this.listing_id });
        this.busy = false;
        if (!res.data.success) throw new Error("Listing status could not be updated");
        this.showToast("info", "Info", res.data.message);
        this.$emit("refresh-listing-table");
        // this.$emit("toggle_sidebar", false);
      } catch (error) {
        this.busy = false;
        console.log(error);
        this.showToast("danger", "Error", error.message ? error.message : "Error while updating listing status");
      }
    },
    async add_user_permission() {
      try {
        this.busy = true;
        let res = await this.$axios.post(`${this.$API_BASE_LISTINGS}/addstocklistpermission`, { user_id: this.selected_user, listing_id: this.listing_id });
        this.busy = false;
        if (!res.data.success) throw new Error("Permissons could not be updated");
        this.initialize();
        this.showToast("info", "Info", res.data.message);
      } catch (error) {
        this.busy = false;
        console.log(error);
        this.showToast("danger", "Error", error.message ? error.message : "Error while updating permissions");
      }
    },
    async remove_user_permission(id) {
      try {
        this.busy = true;
        let res = await this.$axios.post(`${this.$API_BASE_LISTINGS}/removestocklistpermission`, { user_id: id, listing_id: this.listing_id });
        this.busy = false;
        if (!res.data.success) throw new Error("Permissons could not be updated");
        this.initialize();
        this.showToast("info", "Info", res.data.message);
      } catch (error) {
        this.busy = false;
        console.log(error);
        this.showToast("danger", "Error", error.message ? error.message : "Error while updating permissions");
      }
    },
    set_defaults() {
      this.public_listing = true;
      this.selected_user = null;
      this.user_suggestions = [];
      this.permitted_users = [];
    },
    showToast(variant = "info", title = "Info", text = "") {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon: "BellIcon",
          text,
          variant,
        },
      });
    },
    print(i) {
      console.log(i);
      return i.item.email;
    },
  },
  filters: {
    format_fields(str) {
      return typeof str == "string"
        ? str
            .replace(/_/g, " ")
            .replace(/([A-Z]{1}[a-z]+)/g, " $1")
            .trim()
        : str;
    },
    formatDate: function (date) {
      return moment(date).fromNow();
    },
  },
};
</script>

<style lang="scss">
#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
.capitalize-text {
  text-transform: capitalize;
}
.flags-tooltip-container.tooltip .tooltip-inner {
  max-width: 500px !important;
  width: 400px !important;
}
.small-text {
  font-size: 10px;
}
.width-30 {
  width: 30%;
}
.width-70 {
  width: 70%;
}
.w-160p {
  width: 160px;
}
.w-33 {
  width: 33%;
}
.w-40 {
  width: 40%;
}
.w-30 {
  width: 30%;
}
.w-20 {
  width: 20%;
}
.listing_table_fields {
  border-bottom: 1px solid rgb(224, 221, 221);
}

#autosuggest_address {
  width: 100%;
  padding: 0.5rem;
}

.sidebar-autosuggest ul {
  width: 100%;
  color: rgba(30, 39, 46, 1);
  list-style: none;
  margin: 0;
  padding: 0.5rem 0 0.5rem 0;
}
.sidebar-autosuggest li {
  margin: 0 0 0 0;
  border-radius: 5px;
  padding: 0.75rem 0 0.75rem 0.75rem;
  display: flex;
  align-items: center;
}
.sidebar-autosuggest li:hover {
  cursor: pointer;
}

.autosuggest-container {
  display: flex;
  justify-content: center;
  width: 280px;
}

#autosuggest {
  width: 100%;
  display: block;
}
.sidebar-autosuggest .autosuggest__results-item--highlighted {
  background-color: rgba(80, 140, 253, 0.2);
}
.sidebar-autosuggest .autosuggest__results {
  position: absolute;
  width: 100%;
  overflow-y: auto;
  max-height: 50vh;
  z-index: 999;
  background-color: white;
  border: 1px solid rgb(202, 199, 199);
}
</style>
