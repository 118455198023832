var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-sidebar',{attrs:{"id":"true","width":"55%","visible":_vm.sidebar,"bg-variant":"white","sidebar-class":"sidebar-lg","shadow":"","backdrop":"","no-header":"","right":""},on:{"change":function (val) { return _vm.$emit('toggle_sidebar', val); }}},[[_c('b-card',{staticClass:"card-transaction",attrs:{"no-body":""}},[_c('b-card-header',[_c('b-card-title',[_vm._v(" Permission "),_c('small',[_vm._v("( "+_vm._s(_vm.listing_address)+" )")])]),_c('div',[_c('b-button',{staticClass:"float-right",attrs:{"variant":"flat-primary","size":"sm"},on:{"click":function () { return _vm.$emit('toggle_sidebar', false); }}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"XIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v("Close")])],1)],1)],1),_c('b-overlay',{attrs:{"show":_vm.busy,"variant":"transparent","opacity":"0.7","blur":"2.5px"}},[(_vm.permissions)?_c('b-card-body',[[_c('b-row',[[_c('b-col',{staticClass:"mb-1",attrs:{"cols":"12"}},[_c('b-form-checkbox',{staticClass:"mb-2",on:{"change":function($event){return _vm.set_public_listing()}},model:{value:(_vm.public_listing),callback:function ($$v) {_vm.public_listing=$$v},expression:"public_listing"}},[_vm._v(" "+_vm._s(_vm.public_listing ? "Public" : "Private")+" ")])],1)]],2),(!_vm.public_listing)?_c('b-row',[[_c('b-col',{staticClass:"mb-1",attrs:{"cols":"12"}},[_c('label',{staticClass:"mr-2"},[_vm._v("Select User")]),_c('vue-autosuggest',{attrs:{"input-props":{
                      id: 'autosuggest_select_user',
                      class: 'form-control',
                      placeholder: 'Type to get suggestions',
                      disabled: false,
                    },"suggestions":[{ data: _vm.user_suggestions }],"get-suggestion-value":function (i) { return i.item.email; },"limit":20,"component-attr-class-autosuggest-results-container":"sidebar-autosuggest"},on:{"input":_vm.getsuggestions,"selected":_vm.set_autosuggest_value},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var suggestion = ref.suggestion;
return [_c('span',[_vm._v(_vm._s(suggestion.item.name)+" ("+_vm._s(suggestion.item.email)+")")])]}}],null,false,587830186)})],1)]],2):_vm._e()],(!_vm.public_listing)?_c('b-row',[_c('b-col',{staticClass:"mb-1",attrs:{"cols":"12"}},[_c('b-button',{staticClass:"float-right",attrs:{"variant":"flat-primary","size":"sm","disabled":_vm.busy || !_vm.selected_user},on:{"click":function($event){return _vm.add_user_permission()}}},[(!_vm.busy)?_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"SaveIcon"}}):_c('b-spinner',{staticClass:"mr-50",attrs:{"small":"","type":"grow"}}),_c('span',{staticClass:"align-middle"},[_vm._v(_vm._s(_vm.busy ? "Loading..." : "Add User"))])],1)],1)],1):_vm._e(),(!_vm.public_listing)?_c('b-table-simple',{attrs:{"hover":"","small":"","caption-top":"","responsive":""}},[_c('b-thead',{attrs:{"head-variant":"dark"}},[_c('b-tr',[_c('b-th',{staticClass:"text-center",attrs:{"colspan":"2"}},[_vm._v("Permitted Users")])],1)],1),_c('b-tbody',[(_vm.permitted_users.length)?_vm._l((_vm.permitted_users),function(user,key){return _c('b-tr',{key:key},[_c('b-th',[_vm._v(_vm._s(user.email))]),_c('b-td',{staticClass:"text-left"},[_c('b-button',{staticClass:"float-right",attrs:{"variant":"flat-primary","size":"sm"},on:{"click":function($event){return _vm.remove_user_permission(user.user_id)}}},[_c('span',{staticClass:"align-middle"},[_vm._v("Remove")])])],1)],1)}):[_c('b-tr',[_c('b-td',{staticClass:"text-center",attrs:{"colspan":"2"}},[_vm._v("No permitted users found")])],1)]],2)],1):_vm._e()],2):_vm._e()],1)],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }